// const { get } = require("browser-sync");

(() => {


    const mtModal = () => {
        let template = '<div class="mtScreen"></div><div class="mtModal"><div class="mtModalContainer"><div class="mtModalHeader"><div class="grid-container"><div class="grid-x grid-margin-x"><div class="cell small-12"><a href="#" class="mtClose">X</a></div></div></div></div><div class="mtModalContent"><div class="grid-container"><div class="grid-x grid-margin-x grid-padding-y"><div class="cell small-12"><h4>Please fill in the fields below to access your selected materials.</h4><small>Fields Marked with an * are required.</small></div></div><div class="grid-x grid-margin-x grid-padding-y"><div class="cell small-12"><form id="gatedContentForm"><div class="grid-x grid-margin-x"><div class="cell small-12"><label for="firstName">First Name*</label><input type="text" name="firstName" class="firstName"></div><div class="cell small-12"><label for="lastName">Last Name*</label><input type="text" name="lastName" class="lastName"></div><div class="cell small-12"><label for="email">Email*</label><input type="text" name="email" class="email"></div><div class="cell small-12"><input type="checkbox" name="optIn"> I would like to receive marketing communications about MedThink SciCom offerings.</div><div class="cell small-12"><input type="submit" value="SUBMIT" class="upper teal-lt-bg btn dktext"></div></div></form></div></div></div></div></div></div>';

        $('.gatedContent').click(function (e) {
            e.preventDefault();
            let checkSession = sessionStorage.getItem('gated');

            let url = $(this).data('url');
            let material = $(this).data('material');

            if(checkSession === '1') {
                accessDownload(url);
            } else {
                modalMaker(template);
                gatedForm(url, material);
            }
        });
        const gatedForm = (url, material) => {
            $('#gatedContentForm').on('submit', function (e) {
                e.preventDefault();
                let firstName = $('.firstName').val();
                let lastName = $('.lastName').val();
                let email = $('.email').val();

                const data = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    material: material,
                    contactListFolderId: 23601,
                    contactListId: 84884
                }
                $.ajax({
                    url: "https://copay.medthink.com/icontact",
                    type: "POST",
                    data: $.param(data),
                    success: function (success) {
                        successMessage(url);
                    }
                });
                $.ajax({
                    url: "/gated-submit",
                    type: "POST",
                    data: $.param(data),
                    success: function (success) {
                        console.log(success);
                        console.log("Mail");
                    }
                });
            });
        }
        const accessDownload = (url) => {
            window.open(url, '_blank');
        }
        const successMessage = (url) => {
            let setSession = sessionStorage.setItem('gated', '1');
            $('.mtModalContainer').html("<div class='grid-container'><div class='grid-x grid-margin-x grid-padding-y'><div class='cell small-12 text-center'><a href='#' class='mtClose'>X</a><div class='gatedSuccess'><h2>Thank you!</h2><p>Your resource is ready to view. Revisit the list of other resources to download any of additional interest.</p><a href='" + url + "' target='_blank' class='upper teal-lt-bg btn dktext'>View Resource</a></div></div></div></div>");
            $('.mtClose').click(function (e) {
                e.preventDefault();
                modalRemover();
            });
        }
        const modalMaker = (template) => {
            let screen = '<div class="mtScreen"></div>';
            $('body').append(screen);
            $('body').append(template);
            $('body').css('overflow', 'hidden'); 
            $("body").addClass("modal-open");
            $('.mtClose').click(function (e) {
                e.preventDefault();
                modalRemover();
            });
        }
        const modalRemover = () => {
            $('.mtModal').remove();
            $('.mtScreen').remove();
            $('body').css('overflow', 'auto');
            $("body").removeClass("modal-open")
        }
    }
    mtModal();

    const publications = () => {
        let arr = [];
        const getData = () => {
            $.getJSON('/javascripts/pub.json', function(res) {
                arr = res[0].publications;
                
                let pubs = () => {
                    $.each(arr, function(i, item) {
                        if(item.url) {
                            let template = '<div class="cell small-12 medium-4 mix ' + item.category + '"><span data-date="' + item.year + '"></span><div class="mtDecor" style="background-image:url(/images/scicom/thought/' + item.category + '.webp)"></div><div class="mtPubCard"><div class="icon"><img src="/images/scicom/icons/' + item.category + '.png"></div><p class="author mtAuthor">' + item.author + '</p><p><strong class="title match-pub">' + item.title + '</strong></p><p class="description match-desc">' + item.description + '</p><a href="' + item.url + '" class="btns match-btn" target="_blank">Learn More</a></div></div>';
                            $('.pubJug').append(template);
                        } else {
                            let template = '<div class="cell small-12 medium-4 mix ' + item.category + '"><span data-date="' + item.year + '"></span><div class="mtDecor" style="background-image:url(/images/scicom/thought/' + item.category + '.webp)"></div><div class="mtPubCard"><div class="icon"><img src="/images/scicom/icons/' + item.category + '.png"></div><p class="author mtAuthor">' + item.author + '</p><p><strong class="title match-pub">' + item.title + '</strong></p><p class="description match-desc">' + item.description + '</p></div></div>';
                            $('.pubJug').append(template);
                        }
                    });
                    var mixer = mixitup('.pubJug');
                    $('.mtPubCard').matchHeight({byRow: false});
                    $('.mtAuthor').matchHeight({byRow: false});
                    $('.match-pub').matchHeight({byRow: false});
                    $('.match-desc').matchHeight({byRow: false});
                    $('.match-btn').matchHeight({byRow: false});

                    highlights(arr);
                }
                pubs();
            });  
        }
        getData();

        const highlights = (arr) => {
            // Find the latest year in the array
            let latestYear = Math.max(...arr.map(pub => pub.year));
            console.log(latestYear);
            // Filter publications from the latest year
            let publicationsFromLatestYear = arr.filter(pub => pub.year === latestYear);
            console.log(publicationsFromLatestYear);
            // Get a random publication from the filtered pool
            let randomPub = publicationsFromLatestYear[Math.floor(Math.random() * publicationsFromLatestYear.length)];
        
            // Display the random publication
            $('.highlighted .title').append(randomPub.title);
            $('.highlighted .author').append(randomPub.author);
            $('.highlighted .description').append(randomPub.description);
        
            if (randomPub.url) {
                $('.highlighted').append('<a href="' + randomPub.url + '" class="btns" target="_blank">Learn More</a>');
            }
        }
    }
    publications();

    const blogForm = () => {
        let form = $('#blogform');
        form.on('submit', function (e) {
            e.preventDefault();
            let url = '/activate';
            var formData = {};
            $(form).find("input[name], select").each(function (index, node) {
                formData[node.name] = node.value;
            });
            $.post(url, formData).done(function (data) {
                alert(data);
            });
            form.html('<h4>Thank you for your submission. Your <a href="/images/comms/pdf/Every-Interaction-Matters.pdf" class="blue-lt" target="_blank">white paper download</a> is ready.</h4>');
            window.open("/images/comms/pdf/Every-Interaction-Matters.pdf", '_blank');
        });
    }
    blogForm();

    const blogForm2 = () => {
        let form = $('#blogform2');
        form.on('submit', function (e) {
            e.preventDefault();
            let url = '/activate2';
            var formData = {};
            $(form).find("input[name], select").each(function (index, node) {
                formData[node.name] = node.value;
            });
            $.post(url, formData).done(function (data) {
                alert(data);
            });
            form.html('<h4>Thank you for your submission. Your <a href="/images/comms/pdf/MedThink-Communications-Checklist-Improve-HCP-Education.pdf" class="blue-lt" target="_blank">white paper download</a> is ready.</h4>');
            window.open("/images/comms/pdf/MedThink-Communications-Checklist-Improve-HCP-Education.pdf", '_blank');
        });
    }
    blogForm2();

    let currentUrl = window.location.pathname;

    if (currentUrl === "/medthinkcom/our-perspective") {
        var mixer = mixitup('.mixer');
    }


    const filter = () => {
        let select = $('#cats');
        select.change(function () {
            let resp = $(this).val();
            mixer.filter(resp)
                .then(function (state) {
                    console.log(state.activeFilter.selector); // '.category-a'
                });
        });
    }
    filter();

    const search = () => {
        let search = $('#search');
        let button = $('.search');

        button.submit(function (e) {
            e.preventDefault();

            resp = search.value();

            if ($('.card:contains("' + resp + '")')) {
                $('.card:contains("' + resp + '")').parent().addClass('visible');
            }


            mixer.filter('.visible')
                .then(function (state) {
                    console.log(state.activeFilter.selector); // '.category-a'
                });

            $('.card:contains("' + resp + '")').parent().removeClass('visible');
        });

        search.on('change', function () {
            let resp = $(this).val();

            if ($('.card:contains("' + resp + '")')) {
                $('.card:contains("' + resp + '")').parent().addClass('visible');
            }


            mixer.filter('.visible')
                .then(function (state) {
                    console.log(state.activeFilter.selector); // '.category-a'
                });

            $('.card:contains("' + resp + '")').parent().removeClass('visible');
        });
    }

    search();

    const formSubmit = () => {
        const form = $('#contactForm');

        console.log(form);

        form.on('submit', function (e) {
            e.preventDefault();

            console.log("Submitted..")

            const name = $('#name').val();
            const email = $('#email').val();
            const phone = $('#phone').val();
            const message = $('#msg').val();
            const capabilities = $('.capabilities').val();

            const contactListFolderId = 23601;
            let contactListId;

            switch (capabilities) {
                case "MedThink Communications Capabilities":
                    contactListId = 83794;
                    break;
                case "MedThink SciCom Capabilities":
                    contactListId = 83795;
                    break;
                case "Careers":
                    contactListId = 83796;
                    break;
                case "Other":
                    contactListId = 83797;
            }

            const data = {
                firstName: name,
                email: email,
                phone: phone,
                msg: message,
                capabilities: capabilities,
                contactListFolderId: contactListFolderId,
                contactListId: contactListId

            }

            $.ajax({
                url: "https://copay.medthink.com/icontact",
                type: "POST",
                data: $.param(data),
                success: function (success) {
                    console.log(success);
                }
            });

            $.ajax({
                url: "/contact-submit",
                type: "POST",
                data: $.param(data),
                success: function (success) {
                    console.log(success);
                    console.log("Mail");
                }
            });

            $('.wholeForm').html("");
            $('.successer').show();

        });
    }

    formSubmit();

    const backgrounder = () => {
        const bg = $('.bg');

        const og = $('.bg').attr('style');

        let ww = $(window).width();

        if (ww < 900) {
            bg.css('background-image', 'url(/images/scicom/home/hero-dk.png)');
        }

        $(window).resize(function () {
            let ww = $(window).width();
            if (ww < 900) {
                bg.css('background-image', 'url(/images/scicom/home/hero-dk.png)');
            } else {
                bg.attr('style', og);
            }
        });

    }

    backgrounder();

    const active = () => {
        const url = window.location.pathname;
        $('.mtMenu a').each(function () {
            let hrefs = $(this).attr('href');
            if (hrefs == url) {
                $(this).addClass('active');
            }
        });
    }

    active();


    const activeBlog = () => {
        if (window.location.href.indexOf("our-perspective") != -1) {
            $('.perspective-nav').addClass('active');
        }
    }

    activeBlog();

    const mobile = () => {
        $('.hamburger').click(function () {
            $(this).toggleClass('is-active');
            $('.mobileToggle').stop().slideToggle();
        });
    }

    mobile();

    const navigation = () => {
        const dropParent = $('.mtHasDropdown');
        const dropChild = $('.mtDropdown');

        $(dropParent).hover(function (e) {
            const child = $(this).find(dropChild);
            if (dropChild.is(':hidden')) {
                child.show();
            } else {
                child.hide();
            }
        });
    }

    navigation();

    const holiday2022 = () => {

        $('input:radio').change(function(){ 
   $('#holidayquizresults').click(function() {
            if ($('.yesanswer:checked').length >= 3) {
                
                $('#highriskresult').show();

            }
             else if ($('.yesanswer:checked').length >= 1) {
                $('#medriskresult').show();
            } 
            else {
               $('#lowriskresult').show();
            }
            $('#signoffsection').show();
        });                       
});
        
};

 holiday2022();


$('.retakequizcta').click(function() {
 $('input:radio').prop('checked',false);
 $('.riskresult').hide();
 $('#signoffsection').hide();
})


    const hello = (color, msg, brand, value) => {

        let $brand = $(brand);

        const template = "<section class='helloBar " + color + "'><div class='grid-container'><div class='grid-x grid-margin-x'><div class='cell auto show-for-medium'></div><div class='cell small-11 medium-6'><p>" + msg + "</p></div><div class='cell small-1 text-center'><a href='#' class='goodbye'><i class='far fa-times-circle'></i></a></div><div class='cell auto show-for-medium'></div></div></div></section>";

        $brand.prepend(template);


        let hbHeight = $('.helloBar').height();
        let navHeight = $('.sticky').height();

        $('.sticky').animate({
            top: hbHeight + 10
        }, 1000);
        $('.hero').animate({
            marginTop: hbHeight + 10
        }, 1000);

        $('.helloBar').fadeIn(1000);

        $('.mobileToggle').css('top', navHeight);


        $('.goodbye').click(function (e) {
            e.preventDefault();
            $('.helloBar').fadeOut(1000);
            $('.helloBar').remove();
            $('.sticky').animate({
                top: 0
            }, 1000);
            $('.hero').animate({
                marginTop: 0
            }, 1000);
            $('.mobileToggle').animate({
                top: navHeight
            }, 1000);


            Cookies.set(brand, value);
        });

    }

    let cookieMtsc = Cookies.get('.scicom');
    let cookieMtc = Cookies.get('.comms');
    let cookieInc = Cookies.get('.inc');

    let checkmtsc = $('body').hasClass('scicom');
    let checkmtc = $('body').hasClass('comms');
    let checkinc = $('body').hasClass('inc');

    // SCICOM HELLO BAR
    if (checkmtsc && cookieMtsc != '1') {
        hello('teal-md-bg', '<a href="https://www.youtube.com/watch?v=1e891O-eYEs&list=PLIAVapPTHimrcXAh1WfDVlxHS5GaL5Vuw" target="_blank">Explore inspiring ideas in Medical Affairs with our MedThinking video series.</a>', '.scicom', '1');
    }

    // COMM HELLO BAR
    if (checkmtc && cookieMtc != '1') {
         hello('teal-md-bg', 'Parson’s Medical Communications is now part of MedThink.', '.comms', '1');
    }

    // INC HELLO BAR
    // if (checkinc && cookieInc != '1') {
    // hello('teal-md-bg', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a href="">Integer dictum efficitur rhoncus.</a> Etiam lobortis nibh ac odio tempus accumsan. Etiam a ullamcorper urna.', '.inc', '1');
    // }


    $(window).resize(function () {
        let hbHeight = $('.helloBar').height();
        let navHeight = $('.sticky').height();
        $('.sticky').animate({
            top: hbHeight + 20
        }, 100);
        $('.hero').animate({
            marginTop: hbHeight + 20
        }, 100);
        $('.mobileToggle').css('top', 110 + hbHeight);
    });


    const thinker = () => {
        const el = $('.thinker');

        $(window).resize(function () {
            letters();
        });

        const letters = () => {
            el.each(function (i) {
                const letter = $(this).find('h1');
                const lHeight = letter.height();
                const elHeight = $(this).height();
                const maths = (elHeight - lHeight) / 2;
                letter.css('padding-top', maths);
            });
        }

        letters();

        el.hover(function () {
            const msg = $(this).find('.msg');
            const letter = $(this).find('h1');
            const color = $(this).data('color');
            const ww = $(window).width();


            if (ww >= 1260) {
                msg.css('padding-top', 146);
                letter.toggle();
                msg.toggle();
            } else if (ww >= 1024) {
                msg.css('padding-top', 40);
                letter.toggle();
                msg.toggle();
            } else {
                msg.css('padding-top', 10);
            }

            $(this).toggleClass(color);


        });

    }

    thinker();

    $('.match').matchHeight();
    $('.conMatch').matchHeight();
    $('.conMatchAlt').matchHeight();

    $('.match-title').matchHeight();
    $('.match-copy').matchHeight();

    $('.slick').slick({
        arrows: true,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                dots: true,
                arrows: false
            }
        }]
    });

    
    function tab() {
        $('.tab').hide();
        $("#tab-1").show();
        $('.mtTab').click(function(e){
            e.preventDefault();
            let data = $(this).data('tab');
            $('.mtTab').removeClass('actived');
            $(this).addClass('actived');
            $('.tab').hide();
            $('#tab-' + data).show();
        });
    }
    tab();



    // ===== Scroll to Top ====
    // Back to top code
    // If this element is present on the page, run code
    // Credit from https://codepen.io/rdallaire/pen/apoyx
    function backToTop() {
        $(window).scroll(function () {
            if ($('#top').length) { //If there is an ID of top on this page
                if ($(this).scrollTop() >= 500) { // If page is scrolled more than 500px
                    $('#return-to-top').fadeIn(200); // Fade in the arrow
                } else {
                    $('#return-to-top').fadeOut(200); // Else fade out the arrow

                }
            }
        });
        $('#return-to-top').click(function () { // When arrow is clicked
            $('body,html').animate({
                scrollTop: 0 // Scroll to top of body
            }, 650); //speed at which this will scroll to the top
        });

    }
    backToTop();


    // Dynamic Copyright
    function copyright() {
        let thisyear = new Date().getFullYear();
        $('.currentyear').text(thisyear);

    };
    copyright();

    function previousUrl() {
        var url = window.location.pathname.split('/');
        if (url[1] === "medthinkscicom") {
            localStorage.setItem("brandStore", url[1]);
        } else if (url[1] === "medthinkcom") {
            localStorage.setItem("brandStore", url[1]);
        } else {
            localStorage.setItem("brandStore", "general");
        }
    }
    previousUrl();

    function phoneNumberFormat() {
        $('.phone')
            .keydown(function (e) {
                var key = e.which || e.charCode || e.keyCode || 0;
                var $phone = $(this);

                // Don't let them remove the starting '('
                if ($phone.val().length === 1 && (key === 8 || key === 46)) {
                    $phone.val('(');
                    return false;
                }
                // Reset if they highlight and type over first char.
                else if ($phone.val().charAt(0) !== '(') {
                    $phone.val('(' + $phone.val());
                }

                // Auto-format- do not expose the mask as the user begins to type
                if (key !== 8 && key !== 9) {
                    if ($phone.val().length === 4) {
                        $phone.val($phone.val() + ')');
                    }
                    if ($phone.val().length === 5) {
                        $phone.val($phone.val() + ' ');
                    }
                    if ($phone.val().length === 9) {
                        $phone.val($phone.val() + '-');
                    }
                }

                // Allow numeric (and tab, backspace, delete) keys only
                return (key == 8 ||
                    key == 9 ||
                    key == 46 ||
                    (key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105));
            })

            .bind('focus click', function () {
                $phone = $(this);

                if ($phone.val().length === 0) {
                    $phone.val('(');
                }
                else {
                    var val = $phone.val();
                    $phone.val('').val(val); // Ensure cursor remains at the end
                }
            })

            .blur(function () {
                $phone = $(this);

                if ($phone.val() === '(') {
                    $phone.val('');
                }
            });
    }

    // phoneNumberFormat();

    // ICON ANIMATION
    $(document).ready(function () {
        // Check if element is scrolled into view
        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();

            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }
        // If element is scrolled into view, fade it in
        $(window).scroll(function () {
            $('.animated').each(function () {
                if (isScrolledIntoView(this) === true) {
                    $(this).addClass('bounceIn');
                }


            });


        });
    });
})()